import { useSupabase } from '@/api/supabase.client';
import { ArrayElement } from '@/utils/generics';
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import type { Router } from 'trpc_backend/src/trpc/router';
import { BACKEND_URL } from './config';

// todo: move to hook, due to useSupabase() usage
const { accessToken } = useSupabase();

export const client = createTRPCProxyClient<Router>({
  links: [
    httpBatchLink({
      url: new URL('/trpc', BACKEND_URL).toString(),
      headers() {
        return accessToken.value
          ? {
              authorization: `Bearer ${accessToken.value}`,
            }
          : {};
      },
    }),
  ],
});

export type InputTypes = inferRouterInputs<Router>;
export type OutputTypes = inferRouterOutputs<Router>;
export type Conversation = ArrayElement<
  OutputTypes['conversations']['getByQuery']['data']
>;
